<template>
    <div id="apipe-cloud-course-page">
      <top-nav-new />
  
      <div class="apipe-cloud-course-container-park">
        <div class="top-banner d-none d-lg-block">
          <div class="container" style="text-align: left">
            <h2>智慧园区综合解决方案</h2>
            <div class="tit">数智赋能让园区更智能、更便捷、更高效</div>
            <div class="tit">
              面向园区运营方、管理机构、企业及个人提供全栈式数智服务，
            </div>
            <div>
              融合物联网、大数据、AI、数字孪生等领域的产品能力，打造园区全联接、全融合、全智能，
            </div>
            <div class="tit">
              助力园区及企业实现基础设施智能化、运营管理数字化、产业发展生态化的智慧化目标
            </div>
            <div class="exp" @click="handleTest" >立即体验</div>
          </div>
        </div>
        <div class="mobile-banner d-block d-lg-none">
          <div class="container" style="text-align: left">
            <h4>智慧园区综合解决方案</h4>
            <div class="tit">数智赋能让园区更智能、更便捷、更高效</div> 
            <!-- <span class="exp" @click="handleTest" >立即体验</span> -->
          </div>
        </div>
  
        <div class="pc-pro-power d-none d-lg-block">
          <h4 class="pro-tit">产品能力</h4>
          <div class="container text-center">
            <div class="tabs">
              <div
                :class="['items', idx == index ? 'active' : '']"
                v-for="(item, index) in tabList"
                :key="index"
                @click="handleTab(index)"
              >
                <img :src="idx == index ? item.img2 : item.img1" />
                <div>{{ item.tit }}</div>
              </div>
            </div>
            <div class="tab-cot">
              <div class="row" v-show="idx == 0">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p style="text-align:left">
                    通过AIoT+数字孪生技术，实现对集团、区域多项目的可视化运营管理，实时掌控集团整体运行态势，为项目管理人员提供设备运行监测、AI事件告警、能耗等数据可视化能力，提升现场的运营管控及事件响应效率，建立统一的物模型及接口标准，为上层应用提供智能化数据
                  </p>
                  <div style="text-align:left">
                    <button @click="handleTest"  type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p1.png"
                    alt=""
                    style="width: 64%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 1">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p style="text-align:left">
                    自动采集水电气能耗数据，统一能耗数据管理，用能单位、系统分项能耗统计，自动形成周期性报表及用能趋势分析，提供预算编制参考算法，集团下达总体预算目标，项目持续性改进能耗计划，将用能系统结构可视化，分级展示能耗分布及运行占比，直观展示预算与实际用能对比
                  </p>
                  <div style="text-align:left">
                    <button @click="handleTest"  type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p2.png"
                    alt=""
                    style="width: 64%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 2">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p style="text-align:left">
                    视频上云助力园区远程巡视，降本增效；制定园区安全检查标准，通过视频巡更+图片巡更方式进行园区日常巡逻，提高管理效率；设备运行故障、安全事件实时推送指挥中心，园区工程人员远程查看故障，调度现场处理，频AI自动识别园区车辆堵塞现象，及时调度疏导
                  </p>
                  <div style="text-align:left">
                    <button @click="handleTest"  type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p3.png"
                    alt=""
                    style="width: 64%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 3">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p style="text-align:left">
                    融合AIoT技术，实现对设备资产的全生命周期管理，变配电房、给排水房等机电设备智能化实时监测，降低安全隐患，支持根据上下班时间安排自动开启/关闭照明、空调等跨场景智能联动，智能充电桩、巡逻机器人、环卫机器人、配送机器人等智能设备监控
                  </p>
                  <div style="text-align:left">
                    <button @click="handleTest"  type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p4.png"
                    alt=""
                    style="width: 64%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 4">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p style="text-align:left">
                    园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行，访客通行权限可控可管，园区空气、水质监测提升服务质量，车库空气监测联动送排风，营造舒适的办公环境，员工/访客线上缴费、办理月卡，自主快捷通行
                  </p>
                  <div style="text-align:left">
                    <button @click="handleTest"  type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p5.png"
                    alt=""
                    style="width: 64%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 5">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p style="text-align:left">
                    打通园区运营系统、工单等系统，实现智能化数据与业务系统的流程自动化、全场景闭环，可将智能化应用集成至现有员工app，或采用智慧园区独立app解决智能化业务的移动应用办公
                  </p>
                  <div style="text-align:left">
                    <button @click="handleTest"  type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p6.png"
                    alt=""
                    style="width: 64%"
                  />
                </div>
              </div>
              
            </div>
          </div>
        </div>
  
        <div class="mobile-pro-power d-block d-lg-none">
          <h4 class="pro-tit">产品能力</h4>
          <div class="container text-center">
            <div class="tabs">
              <div
                :class="['items', idx == index ? 'active' : '']"
                v-for="(item, index) in tabList"
                :key="index"
                @click="handleTab(index)"
              >
                <img :src="idx == index ? item.img2 : item.img1" />
                <div>{{ item.tit }}</div>
                <div>{{ item.tit2 }}</div>
              </div>
            </div>
            <div class="tab-cot">
              <div class="row" v-show="idx == 0">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    通过AIoT+数字孪生技术，实现对集团、区域多项目的可视化运营管理，实时掌控集团整体运行态势，为项目管理人员提供设备运行监测、AI事件告警、能耗等数据可视化能力，提升现场的运营管控及事件响应效率，建立统一的物模型及接口标准，为上层应用提供智能化数据
                  </p>
                  <div>
                    <!-- <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p1.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 1">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    自动采集水电气能耗数据，统一能耗数据管理，用能单位、系统分项能耗统计，自动形成周期性报表及用能趋势分析，提供预算编制参考算法，集团下达总体预算目标，项目持续性改进能耗计划，将用能系统结构可视化，分级展示能耗分布及运行占比，直观展示预算与实际用能对比
                  </p>
                  <div>
                    <!-- <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p2.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 2">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    视频上云助力园区远程巡视，降本增效；制定园区安全检查标准，通过视频巡更+图片巡更方式进行园区日常巡逻，提高管理效率；设备运行故障、安全事件实时推送指挥中心，园区工程人员远程查看故障，调度现场处理，频AI自动识别园区车辆堵塞现象，及时调度疏导
                  </p>
                  <div>
                    <!-- <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p3.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 3">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    融合AIoT技术，实现对设备资产的全生命周期管理，变配电房、给排水房等机电设备智能化实时监测，降低安全隐患，支持根据上下班时间安排自动开启/关闭照明、空调等跨场景智能联动，智能充电桩、巡逻机器人、环卫机器人、配送机器人等智能设备监控
                  </p>
                  <div>
                    <!-- <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p4.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 4">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    园区通过人脸、手机一键开门，通行无卡化、安全便捷，员工app自主登记邀约，访客到访自主无感通行，访客通行权限可控可管，园区空气、水质监测提升服务质量，车库空气监测联动送排风，营造舒适的办公环境，员工/访客线上缴费、办理月卡，自主快捷通行
                  </p>
                  <div>
                    <!-- <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p5.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="idx == 5">
                <div class="col-xs-12 col-md-6 lf">
                  <!-- <h6>数字化办公平台——伟班</h6> -->
                  <p>
                    打通园区运营系统、工单等系统，实现智能化数据与业务系统的流程自动化、全场景闭环，可将智能化应用集成至现有员工app，或采用智慧园区独立app解决智能化业务的移动应用办公
                  </p>
                  <div>
                    <!-- <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/parkSolution/p6.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              
            </div>
          </div>
        </div>
         <div class="more-function">
          <h4 class="pro-tit">众多功能应用，助力智慧园区良性发展</h4>
          <h6 class="pro-tits">
            智能应用和服务优化，提高园区的整体运营效率
          </h6>
          <div class="container text-center">
            <div class="row row-cols-4 row-cols-lg-6 g-2 g-lg-3">
              <div class="col" v-for="(item,index) in funList" :key="index">
                <div class="p-3">
                  <img :src="item.img" alt="" />
                  <h6>{{ item.tit }}</h6>
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
  
  
        <div class="scene">
          <h4 class="pro-tit">应用场景</h4>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a1.png" alt="" />
                  <h6>环境监测</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a2.png" alt="" />
                  <h6>智慧消防</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a3.png" alt="" />
                  <h6>智慧停车</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a4.png" alt="" />
                  <h6>综合安防</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a5.png" alt="" />
                  <h6>能耗管理</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a6.png" alt="" />
                  <h6>智能路灯</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a7.png" alt="" />
                  <h6>智能门禁</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a8.png" alt="" />
                  <h6>访客管理</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a9.png" alt="" />
                  <h6>设施管理</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a10.png" alt="" />
                  <h6>智能充电桩</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a11.png" alt="" />
                  <h6>智能电梯</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/parkSolution/a12.png" alt="" />
                  <h6>智能会议室</h6>
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
  
        <div class="cooperation d-none d-lg-block">
          <h4 class="pro-tit">合作客户</h4>
          <div class="wrap container">
              <div class="items" v-for="(item,index) in funList2" :key="index"  @mouseenter="enter(index)" @mouseleave="leave()">
                <div class="p-3" style="height: 180px;">
                  <img :src="item.img" alt="" /> 
                </div>
                <div class="des" v-if="ids == index" style="height: 180px;">
                    <h4>{{ item.tit }}</h4>
                    <div style="font-size:12px;">{{ item.des }}</div>
                </div>
              </div>
   
            </div>
        </div>
  
        <div class="mobile-cooperation d-block d-lg-none">
          <h4 class="pro-tit">合作客户</h4>
          <div class="wrap">
              <div class="items" v-for="(item,index) in funList2" :key="index" >
                <div class="p-3" >
                  <img :src="item.img" alt="" /> 
                </div>
              </div>
   
            </div>
        </div>
   
  
        <right-nav-new class="d-none d-lg-block"/>
  
      </div>
  
      <NewFooter />
      <MobileFooter class="d-block d-lg-none" />
    </div>
  </template>
      
      <script>
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import SwiperCore, { Autoplay } from "swiper";
  SwiperCore.use([Autoplay]);
  
  export default {
    name: "hardware",
    data() {
      return {
        activeName: "1",
        idx: 0,
        ids:-1,
        tabList: [
          {
            img1: require("@/assets/newSite/parkSolution/s1.png"),
            img2: require("@/assets/newSite/parkSolution/s1-1.png"),
            tit: "数据可视",
            tit2: "化及应用",
          },
          {
            img1: require("@/assets/newSite/parkSolution/s2.png"),
            img2: require("@/assets/newSite/parkSolution/s2-2.png"),
            tit: "能耗",
            tit2: "管理",
          },
          {
            img1: require("@/assets/newSite/parkSolution/s3.png"),
            img2: require("@/assets/newSite/parkSolution/s3-3.png"),
            tit: "安全",
            tit2: "管理",
          },
          {
            img1: require("@/assets/newSite/parkSolution/s4.png"),
            img2: require("@/assets/newSite/parkSolution/s4-4.png"),
            tit: "设备",
            tit2: "运维",
          },
          {
            img1: require("@/assets/newSite/parkSolution/s5.png"),
            img2: require("@/assets/newSite/parkSolution/s5-5.png"),
            tit: "服务",
            tit2: "体验",
          },
          {
            img1: require("@/assets/newSite/parkSolution/s6.png"),
            img2: require("@/assets/newSite/parkSolution/s6-6.png"),
            tit: "集成",
            tit2: "能力",
          },
        ],
        funList:[
          {
              tit:'招商运营',
              img: require("@/assets/newSite/parkSolution/f1.png"),
          },
          {
              tit:'企业服务',
              img: require("@/assets/newSite/parkSolution/f2.png"),
          },
          {
              tit:'数字办公',
              img: require("@/assets/newSite/parkSolution/f3.png"),
          },
          {
              tit:'资产管理',
              img: require("@/assets/newSite/parkSolution/f4.png"),
          },
          {
              tit:'物业服务',
              img: require("@/assets/newSite/parkSolution/f5.png"),
          },
          {
              tit:'生活服务',
              img: require("@/assets/newSite/parkSolution/f6.png"),
          },
          {
              tit:'园区安防',
              img: require("@/assets/newSite/parkSolution/f7.png"),
          },
          {
              tit:'便捷通行',
              img: require("@/assets/newSite/parkSolution/f8.png"),
          },
          {
              tit:'楼宇管理',
              img: require("@/assets/newSite/parkSolution/f9.png"),
          },
          {
              tit:'设施管理',
              img: require("@/assets/newSite/parkSolution/f10.png"),
          },
          {
              tit:'能效管理',
              img: require("@/assets/newSite/parkSolution/f11.png"),
          },
          {
              tit:'环境空间',
              img: require("@/assets/newSite/parkSolution/f12.png"),
          } 
        ],
 
        funList2:[
        {
          tit: "华瀚控股",
          img: require("@/assets/newSite/solution/c1.png"),
          des: "深圳市华瀚科技控股有限公司是在2000年第二届“高交会”上签约成立，现已发展成为以智慧管网为特征的管道系统解决方案服务商。公司总部位于深圳市高新技术产业园区、深圳生产基地在坪山新区，在成都、长沙、武汉、昆明等地均有产业基地。优制云根据其需求为其定制全套数字化方案。",
        },
        {
          tit: "锦瀚智慧管网",
          img: require("@/assets/newSite/solution/c2.png"),
          des: "锦瀚智慧管网技术有限公司成立于2013年，注册资本5000万，公司位于深圳市高新技 术产业园北区。主要围绕智慧城市地下透明空间，研究、设计、开发、生产智慧管网(管廊)、智 能管材、智能传感及管网周边智能化硬件等软硬件系列产品，致力于城市地下透明空间产业 的发展。优制云根据其需求为其定制全套数字化方案。",
        },
        {
          tit: "优管网",
          img: require("@/assets/newSite/you.png"),
          des: "优管网致力于服务中小实体企业,以管道产品及其设备、塑料大宗商品、智能制造应用技术三个专业领域为特色,构建工业互联网交易平台,让商家快速触达海量用户,让买家快速找到优质货源。优制云根据其需求为其定制全套数字化方案。",
        },
   
        ],
 
      };
    },
    components: {
      Swiper,
      SwiperSlide,
    },
  
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleTest(){
     window.open('https://apipecloud.veiban.com/login')
    },
      handleLeft() {
        const arrs = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];
        const current = this.activeName;
        let posIdx = arrs.findIndex((n) => n == current);
        if (posIdx == 0) return;
        this.activeName = arrs[posIdx - 1];
      },
      handleRight() {
        const arrs = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];
        const current = this.activeName;
        let posIdx = arrs.findIndex((n) => n == current);
        if (current == arrs.length - 1) return;
        this.activeName = arrs[posIdx + 1];
      },
      handleTab(index) {
        this.idx = index;
      },
      enter(index){
           this.ids = index
      },
      leave(){
          this.ids = -1
      }
  
    },
    destroyed() {
      window.removeEventListener("scroll", this.onScroll, false);
    },
  };
  </script>
      
      <style lang="less"  scoped>
  .apipe-cloud-course-container-park {
    .top-banner {
      height: 600px;
      background-image: url("../../assets/newSite/parkSolution/banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      .container {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .tit {
          font-size: 16px;
        }
        .exp {
          margin-top: 2rem;
          font-size: 14px;
          border: 1px solid white;
          display: inline-block;
          width: 100px;
          padding: 2px 0;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .mobile-banner {
      height: 200px;
      background-image: url("../../assets/newSite/parkSolution/banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      .container {
        color: white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .tit {
          font-size: 12px;
        }
        .exp {
          margin-top: 0.5rem;
          font-size: 12px;
          border: 1px solid white;
          display: inline-block;
          width: 80px;
          text-align: center;
        }
      }
    }
  
    .pc-pro-power {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
      }
      .tabs {
        display: flex;
        .items {
          font-size: 14px;
          flex: 25%;
          height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          &:hover {
            cursor: pointer;
          }
          img {
            width: 60px;
            height: 60px;
          }
        }
        .active {
          background: rgba(20, 132, 255, 1);
          color: white;
        }
      }
      .tab-cot {
        //   border: 1px solid red;
        
        padding: 20px;
        .row {
          min-height: 300px;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          // text-align: left;
          padding: 20px;
          .lf {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 100px;
            }
          }
          p {
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
  
    .mobile-pro-power {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
      }
      .tabs {
        display: flex;
        justify-content: space-around;
        .items { 
          font-size: 14px; 
          height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 5px;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          &:hover {
            cursor: pointer;
          }
          img {
            width: 40px;
            height: 40px;
          }
        }
        .active {
          background: rgba(20, 132, 255, 1);
          color: white;
        }
      }
      .tab-cot {
        //   border: 1px solid red;
        
        padding: 20px;
        .row {
          min-height: 300px;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          // text-align: left;
          padding: 10px;
          .lf {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 100px;
            }
          }
          p {
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
    
    .more-function {
      margin: 50px 0;
      .pro-tits {
        color: #666;
        margin-bottom: 30px;
        padding: 0 10px;
      }
      .col {
        .p-3 { 
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          padding: 24px 0  10px 0 !important;
        }
      }
  
      img {
        width: 40px;
        height: 40px;
      }
      p {
        color: #888;
        font-size: 13px;
      }
      h6 {
        margin-top: 1rem;
      }
    }
  
  
    .scene {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
      }
      .col {
        .p-3 {
          min-height: 200px;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        }
      }
      img {
        width: 100%;
        height: 200px;
      }
      h6 {
        margin-top: 1rem;
      }
    }
  
  
  
  
   .cooperation{
      margin-bottom: 30px;
      .pro-tit{
          margin-bottom: 30px;
      }
     .wrap{
       display: flex; 
       justify-content: center;
       .items{
          margin: 0 20px; 
          position: relative;
          flex: 1;
          .p-3{
          height: 180px;
          // width: 280px !important; 
          // border: 1px solid red;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow:0px 2px 20px rgba(0, 0, 0, 0.1);
          img{
              width: 50%;
          }
          }
  
          .des{ 
              position: absolute;
              height: 100%;
              left: 0 ;
              top: 0;
              background: rgba(20, 132, 255, 1);
              color: white;
              padding: 20px;
          }
       }
     }
   }
  
   .mobile-cooperation{
      margin-bottom: 30px;
      .pro-tit{
          margin-bottom: 30px;
      }
     .wrap{
  
       .items{
          display: flex; 
      //  flex-direction: column;
       justify-content: center;
          margin:  20px 0;  
          .p-3{
          height: 150px;
          width: 280px !important;  
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow:0px 2px 20px rgba(0, 0, 0, 0.1);
          img{
              width: 50%;
          }
          }
   
       }
     }
   }
  
  
  
  
  
  
  
  
  
  
  
  
  }
  </style>
      